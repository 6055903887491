import React, { useState } from 'react';

import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './Footer.scss';

const Footer = () => {
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const { username, email, message } = formData;

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = () => {
        if (username !== "" && email !== "" && message !== "") {
            setLoading(true);

            const contact = {
                _type: 'contact',
                name: formData.username,
                email: formData.email,
                message: formData.message,
            };

            client.create(contact)
                .then(() => {
                    setLoading(false);
                    setIsFormSubmitted(true);
                })
                .catch((err) => console.log(err));
        }
    };

    return (
        <>
            <h2 className="head-text">Feel free to contact me anytime</h2>

            <div className="app__footer-cards">
                <a href="mailto:fazrez4515@gmail.com" className="app__footer-card ">
                    <img src={images.email} alt="email" />
                    <p className="p-text">fazrez4515@gmail.com</p>
                </a>
                <a href="tel:+601113152704" className="app__footer-card">
                    <img src={images.mobile} alt="phone" />
                    <p className="p-text">+60 111 315 27 04</p>
                </a>
            </div>
            {!isFormSubmitted ? (
                <div className="app__footer-form app__flex">
                    <div className="app__flex">
                        <input className="p-text" type="text" placeholder="Your Name" name="username" value={username} onChange={handleChangeInput} />
                    </div>
                    <div className="app__flex">
                        <input className="p-text" type="email" placeholder="Your Email" name="email" value={email} onChange={handleChangeInput} />
                    </div>
                    <div>
                        <textarea
                            className="p-text"
                            placeholder="Your Message"
                            value={message}
                            name="message"
                            onChange={handleChangeInput}
                        />
                    </div>
                    <button type="button" className="p-text" onClick={handleSubmit}>{!loading ? 'Send Message' : 'Sending...'}</button>
                </div>
            ) : (
                <div>
                    <h3 className="head-text">
                        Thank you for getting in touch!
                    </h3>
                </div>
            )}
        </>
    );
};

export default AppWrap(
    MotionWrap(Footer, 'app__footer'),
    'contact',
    'app__whitebg',
);